import template from './report_menu.html';

class ReportMenuViewModel
{
	constructor (page)
	{
		this.page = page;
		this.report_definitions = ko.observableArray([]);
		this.available_groups = ko.observableArray([]);
		this.selected_group = ko.observable('');
		this.cached_selected_group = ko.observable();
		this.hide_inactive_definitions = ko.observable(true);
		this.cached_hide_inactive_definitions = ko.observable();
		this.session_id = Grape.currentSession.session_id.substring(23, 29);
		this.search_string = ko.observable('');

		this.search_string.subscribe((newValue) => {
			this.get_report_definitions();
		});
	}

	async get_report_definitions ()
	{
		let group_value = this.cached_selected_group();
		if ((!group_value) || group_value == 'Choose Group')
			group_value = null;

		let result = await Grape.fetches.getJSON('/api/report/definition/all', {
			'group': group_value, 
			'active': this.cached_hide_inactive_definitions(), 
			'name': this.search_string()
		});

		if (result.status !== 'ERROR')
			this.report_definitions(result.records);
		else
			Grape.alert_api_error(result);
	}

	async add_report (event)
	{
		// Create report by calling the ReportDefinitionDialog
		let response = await Grape.dialog.open('ReportDefinition', {action: 'create'});

		if (response)
			this.get_report_definitions();
	}

	show_inactive ()
	{
		this.hide_inactive_definitions( (! this.hide_inactive_definitions()) );
	}

	row_click (report_definition)
	{
		let url = ['/reports/definition', report_definition.report_definition_id, report_definition.version].join('/');
		Grape.navigate(url);
	}
}

class ReportMenuPage 
{
	constructor (bindings, element)
	{
		this.bindings = bindings;
		this.element = element;
		this.viewModel = new ReportMenuViewModel(this);
		this.name = 'ReportMenuPage';
		this.has_data = false;
	}

	init ()
	{
		this.viewModel.cached_selected_group(
			sessionStorage.getItem( this.session_id + '_selected_group') || 'Choose Group' );

		let show_inactive = sessionStorage.getItem( this.session_id + '_active' );

		if (show_inactive === 'false')
			show_inactive = false;
		else
			show_inactive = true;
		this.viewModel.cached_hide_inactive_definitions(show_inactive);

		this.viewModel.hide_inactive_definitions.subscribe((newValue) => {
			if (this.has_data)
			{
				sessionStorage.setItem( this.session_id + '_active', newValue );
				this.viewModel.cached_hide_inactive_definitions(newValue);
				this.updateData();
			};
		});

		this.viewModel.selected_group.subscribe((newValue) => {
			if (this.has_data)
			{
				sessionStorage.setItem( this.session_id + '_selected_group', this.viewModel.selected_group() );
				this.viewModel.cached_selected_group(this.viewModel.selected_group());
				this.updateData();
			};
		});
	}

	async updateData ()
	{
		let available_groups = [];

		let result = await Grape.fetches.getJSON('/api/report/definition/groups', {'active': this.viewModel.cached_hide_inactive_definitions()});
		if (result.status !== 'ERROR')
		{
			result.records.forEach((record) => {
				if(available_groups.indexOf(record.group) == -1)
					available_groups.push(record.group);
			});

			available_groups.unshift('Choose Group');
			let old_value = this.viewModel.selected_group();
			this.viewModel.available_groups(available_groups);
			this.viewModel.selected_group(old_value);
			this.viewModel.selected_group(this.viewModel.cached_selected_group());
			this.viewModel.hide_inactive_definitions(this.viewModel.cached_hide_inactive_definitions());
			this.has_data = true;
		}
		else
			Grape.alert_api_error(result);

		this.viewModel.get_report_definitions();
	}
}

export default {
	route: '[/]reports/',
	page_class: ReportMenuPage,
	template: template,
	page_id: '[/]reports/',
	title: 'Reports',
	icon: 'fa fa-file-chart-column',
	idx: 10
};
