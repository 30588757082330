
import template from './send_email_dialog.html';

class EmailDialogViewModel
{
	constructor (page)
	{
		this.page = page;
		this.availableList = ko.observableArray();
		this.selectedAvailableList = ko.observable();
	}

	btn_send_click ()
	{
		this.page.close(this.selectedAvailableList());
	}

	btn_close_click ()
	{
		this.page.close(false);
	}
}

class EmailDialogClass
{

	constructor (bindings, elements)
	{
		this.bindings = bindings;
		this.elements = elements;
		this.viewModel = new EmailDialogViewModel(this);
		this.name = 'EmailDialog';
	}

	init ()
	{
		if (this.bindings !== undefined && this.bindings.options.length > 0)
		{
			let arr = [];
			
			this.bindings.options.forEach((s) => { arr.push(s.title); });
			this.viewModel.availableList(arr);
		}
	}
}

export default {
	name: 'EmailDialog',
	dialog_class: EmailDialogClass,
	template: template,
	provider: "ps"
};
