import template from './report_autoscheduler.html';

class scheduledReportViewModel
{
	constructor (page)
	{
		this.page = page;
		this.show_hide_active_schedules = ko.observable(false);
		this.list = ko.observableArray([]);
		this.process_id = ko.observable();
		this.process_name = ko.observable();

		this.show_hide_active_schedules.subscribe(() => {
			this.download_list();
		});	
	}

	format_dow (dow)
	{
		let days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
		return days.filter((day, index) => dow[index] === '1').join(', ');
	}

	format_interval (interval)
	{
		if (!interval || interval === 'N/A') return 'N/A';

		let parts = interval.split(':');
		let hours = parseInt(parts[0], 10);
		let minutes = parseInt(parts[1], 10);
		let formatted_interval = 'Every ';

		if (hours > 0) formatted_interval += `${hours} hours `;
		if (minutes > 0) formatted_interval += `${minutes} minutes`;
		return formatted_interval.trim();
	}

	format_day_time (day_time)
	{
		if (!day_time || day_time === 'N/A') return 'N/A';
		let parts = day_time.split(':');
		return parts[0] + ':' + parts[1];
	}

	async download_list ()
	{
		let results;
		if (this.show_hide_active_schedules() === false)
		{
			results = await Grape.fetches.getJSON('/api/record', {
				schema: 'reports', 
				table: 'v_report_autoscheduler', 
				filter: [{
					field: 'active',
					operand: '!=',
					value: 'false'
				}]
			});
		}
		else
		{
			results = await Grape.fetches.getJSON('/api/record', {
				schema: 'reports', 
				table: 'v_report_autoscheduler'
			});
		}

		if (results)
		{
			if (results && results.records == null)
				results.records = [];

			this.list(results.records);
		}

		if (results && results.records)
			this.list(results.records);

		let process_data = await Grape.fetches.getJSON("/api/record", {
			schema: 'bgworker', 
			table: 'process', 
			filter: [{field: 'process_name', operand: '=', value: 'report_create'}]
		});

		if (process_data)
		{
			if (process_data.status === "ERROR")
				return;
			else if (process_data.result_count == 0)
				return;
			else
			{
				let record = process_data.records[0];
				this.process_name(record.process_name);
				this.process_id(record.process_id);
			}
		}
	}

	btn_show_inactive_click ()
	{
		this.show_hide_active_schedules( !this.show_hide_active_schedules());
	}

	async btn_autoscheduling_edit_or_create_click (arg)
	{
		let mode;
		let auto_scheduler;

		if(arg != undefined)
			mode = 'edit';
		else
			mode = 'create';

		if (Grape.currentSession.roles.indexOf('admin') === -1)
		{
			Grape.alerts.alert({
				type: 'warning',
				message: 'User does not have the correct permission to run autoscheduling',
				title: 'Autoscheduling Error'
			});

			return;
		}
		else
		{
			if (mode === 'edit')
				auto_scheduler = [arg];
			else
				auto_scheduler = [];

			let process = { auto_schedulers: auto_scheduler };

			let result = await Grape.dialog.open('CreateReportAutoSchedule', {process: process, mode: mode});
			if (result)
				this.download_list();
		}
	}

	btn_help_click ()
	{
		// TODO: Grape.show_help does not exist anymore
		// Grape.show_help('/reports/help/report_autoscheduler_help.html');
		console.log('show help clicked');
	}
}

class ScheduledReportPage
{
	constructor (bindings)
	{
		this.bindings = bindings;
		this.viewModel = new scheduledReportViewModel(this);
		this.name = 'ScheduledReportPage';
	}

	init ()
	{
		this.viewModel.download_list();
	}
}

export default {
	route: '[/]reports/report_autoscheduler',
	page_class: ScheduledReportPage,
	template: template
}
