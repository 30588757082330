import template from './report.html';

class ReportViewModel
{
	constructor (page)
	{
		this.page = page;
		this.report_definition = ko.observable();
		this.schema_name = ko.observable('reports_data');
		this.table_name = ko.observable();

		this.title = ko.observable('Placeholder');
		this.description = ko.observable('Placeholder');
		this.query = {query_sql: ko.observable('')};
		this.report_status_message = ko.observable('');
		this.report_id = this.page.bindings.report_id;
		this.report_created_date = ko.observable();
		this.flag = ko.observable(false);

		this.report_definition_id = ko.observable();;
		this.version = ko.observable();
		this.email_list = ko.observableArray([]);
		this.table_data = ko.observableArray([]);
		this.report_data;

		//table Info
		this.record_total = ko.observable('');
		this.total_pages = ko.observable('');

		this.is_error = ko.observable(false);
		ko.bindingHandlers.alert_status = {
			update: (element, valueAccessor) => {
				let value = ko.unwrap(valueAccessor());
				if (value != 'warning' && value != 'danger')
				{
					ko.utils.toggleDomNodeCssClass(element, 'ps-alert-danger', false);
					ko.utils.toggleDomNodeCssClass(element, 'ps-alert-info', true);
					ko.utils.toggleDomNodeCssClass(element, 'ps-alert-warning', false);
				}
				else if (value === 'warning')
				{
					ko.utils.toggleDomNodeCssClass(element, 'ps-alert-danger', false);
					ko.utils.toggleDomNodeCssClass(element, 'ps-alert-info', false);
					ko.utils.toggleDomNodeCssClass(element, 'ps-alert-warning', true);
				}
				else if (value === 'danger')
				{
					ko.utils.toggleDomNodeCssClass(element, 'ps-alert-danger', true);
					ko.utils.toggleDomNodeCssClass(element, 'ps-alert-info', false);
					ko.utils.toggleDomNodeCssClass(element, 'ps-alert-warning', false);
				}
			}
		};
	}

	async load ()
	{
		// Fetch report definition based on the report definition id
		let query_obj = {
			schema: 'reports',
			table: 'v_reports',
			fields: ['name', 'description', 'report_definition_id', 'version'],
			filter: [
				{field: 'report_id', operand: '=', value: this.page.bindings.report_id }
			],
			limit: 1 
		};

		let response = await Grape.fetches.getJSON('/api/record', query_obj);
		if (response.status === 'OK')
		{
			let report = response.records[0];
			this.title(report.name);
			this.description(report.description);
			this.report_definition_id(report.report_definition_id);
			this.version(report.version);
			this.report_definition(report);
		}
		else
			throw new Error('Error!');
	}

	async navigation_url ()
	{
		this.getResults(this.page.bindings.report_id);
	}

	async getResults (report_id) // Select or generate the report corresponding with the query or the function
	{
		let data = await Grape.fetches.getJSON('/api/report', {'report_id': report_id});
		if (data)
		{
			if (data.metadata)
			{
				this.report_data = data.metadata;
				this.report_created_date(data.metadata.date_inserted);
			}

			if (data.status === 'ERROR' || ['Error', 'Completed', 'NewTask', 'Running'].indexOf(data.report_status) < 0 )
			{
				this.is_error('warning');
				this.report_status_message('Error - no report data to display! Please ensure the query, table, or function has data!');
				return null;
			}

			if (data.report_status === 'Error')
			{
				this.is_error('danger');
				this.report_status_message(data.report_status);

				let response = await Grape.alerts.confirm({
					type: 'error', 
					title: 'Error!', 
					message: 'An unknown error occurred. Please ensure the query is valid or contact IT support. Do you want to open a log file?'
				});

				if (response)
					window.open('/api/bgworker/schedules/'+report_id+'/log', '_blank');
			}
			else if (data.report_status === 'Completed')
			{
				this.is_error(false);
				this.report_status_message('');
				this.table_name(data.result_table);

				let options = {
					schema: this.schema_name(),
					table: this.table_name()
				}
				
				let result = await Grape.fetches.getJSON('/api/record', options);
				if (result)
				{
					if (result.records.length > 0)
					{
						this.table_data(result.records);
						this.record_total(result.total);
						this.total_pages(result.total_pages);
					}
					else
					{
						Grape.alerts.alert({
							type: 'warning', 
							title: 'No data!', 
							message: 'There is no data to display for this report.'
						});
					}
				}
			}
			else
			{
				if (data.report_status === 'Running')
					this.report_status_message(data.report_status);
				else if (data.report_status === 'NewTask')
					this.report_status_message(data.report_status);

				// Try again in 2000 milliseconds
				this.timer = setTimeout(
					() => {
						this.getResults(report_id);
					},
					2000
				);
			}
		}
	}

	async send_to_email (v,e)
	{
		//Grape.alerts.alert({ type: 'warning', title: 'NOTICE', message: 'TODO: fix email list functionality' });
		// TODO: use dialog Grape.dialog.open('EmailDialog');

		let	email_params = {
			schema: 'reports',
			table: 'email_list',
			sortfield: 'email_list_id',
			limit: 5000
		};

		let results = await Grape.fetches.getJSON('/api/record', email_params);
		if (results.status === 'OK')
		{
			
			if (results.result_count == 0)
				results.records = [];

			this.email_list([]);

			results.records.forEach((record) => {
				this.email_list.push({'value': record.list_id, 'text': record.title});
			});

			let answer = await Grape.dialog.open('EmailDialog', {options: this.email_list()});
			
			if (answer)
			{
				let response = await Grape.fetches.postJSON('/api/reports/email',
				{
					report_id: this.page.bindings.report_id,
					email_list_id: answer.email_list_id,
					url: ['/reports/report', String(this.report_definition_id()), String(this.version()),String(this.page.bindings.report_id)].join('/')
				});

				if (response.status === 'ERROR')
				{
					Grape.alerts.alert({
						type: 'warning', 
						title: 'Unknown error', 
						message: 'We have encountered an unknown error. Please contact IT support to look into this matter. [2]'
					});
					return null;
				}
			}
			else
				return;
			
			this.email_list.removeAll();
		}
		else 
			throw new Error('Error!');
	}

	async refreshReportResults ()
	{
		let data = await Grape.fetches.postJSON('/api/report/create',
		{
			report_definition_id: this.report_definition_id(),
			version: this.version(),
			report_function_criteria: this.report_data.report_function_criteria
		});

		if (data.status === 'ERROR')
		{
			let url = ['/reports/report', String(this.report_definition_id()), String(this.version()), String(data.report.report_id)].join('/');
			Grape.navigate(url);
		}
	}

	async cancelProcessReports (data)
	{
		let decision = await Grape.alerts.confirm({
			type: 'warning',
			title: 'Delete Report',
			message: 'Delete the generated report and return to Report Definition page'
		});

		if (decision)
		{
			if (decision === true)
			{
				let reports = [
					{'report_id': this.report_id}
				];

				let url = ['/reports/definition', String(this.report_definition_id()), String(this.version)].join('/');
				let process_cancel_result = await Grape.fetches.postJSON('/api/reports/delete', {reports});
	
				if (process_cancel_result != -1)
				{
					this.report_status_message('Report creation was canceled');
					clearTimeout(this.timer);
					Grape.navigate(url);
				}
				else
					Grape.alerts.alert({type: 'warning', title: 'Unknown error', message: 'We have encountered an unknown error. Please contact IT support to look into this matter. [3]'});
			}
			else
				return;
		}
	}

	backToTop ()
	{
		document.body.scrollTop = 0; // For Safari
		document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
	}

	backToReportDefinition ()
	{
		Grape.navigate(`/reports/definition/${String(this.report_definition_id())}/${String(this.version())}`);
	}

	copyLink ()
	{
		let url = window.location.href;
		let link = `<a href='${url}'>${url}</a>`;
		navigator.clipboard.writeText(url);
		Grape.alerts.alert({type:'info', title:'Report Link Copied', message:link});
	}

	downloadCSV ()
	{
		let formatted_date = new Date(this.report_created_date());
		let export_filename = this.title()+' v'+this.report_id+'('+formatted_date.getFullYear() + '-' + (formatted_date.getMonth() + 1) + '-' + formatted_date.getDate() + ' ' + formatted_date.getHours() + ':' + formatted_date.getMinutes() + ':' + formatted_date.getSeconds()+').csv';
		window.open('/reports/'+this.report_id+'/export/csv/'+export_filename, '_blank');
	}

	btn_help_click ()
	{
		console.log('help clicked');
		//TODO add help menu again
		//Grape.show_help('/reports/help/report_menu_help.html');
	}
}

class ReportPage
{
	constructor (bindings, element) 
	{
		this.bindings = bindings;
		this.element = element;
		this.viewModel = new ReportViewModel(this);
		this.name = 'ReportPage';
	}

	async init ()
	{
		this.viewModel.load();
	}

	updateData ()
	{
		this.viewModel.navigation_url();
	}

	teardown ()
	{
		clearTimeout(this.viewModel.timer);
	}
}

export default {
	route: '[/]reports/report?report_id=:report_id',
	page_class: ReportPage,
	template: template
};
