
import template from './user_email_list_dialog.html';

class UserEmailListDialogViewModel
{
	constructor (page)
	{
		this.page = page;
		this.title = ko.observable();
		this.action = ko.observable();
		this.email_title = ko.observable('');
		this.availableUsers = ko.observableArray();
		this.selectedAvailableUsers = ko.observableArray();
		this.availableRoleNames = ko.observableArray();
		this.selectedAvailableRoleNames = ko.observableArray();
		this.extra_email_address = ko.observableArray();
		this.extraEmailList = ko.observableArray();
	}

	btn_add_click ()
	{
		this.extraEmailList([]);
		this.extra_email_address.push({value: ko.observable('')});
	}

	btn_remove_click (data)
	{
		this.extra_email_address.remove(data);
	}

	btn_close_click ()
	{
		this.page.close(false);
	}

	async btn_save_click ()
	{
		this.extra_email_address().forEach((data) => {
			this.extraEmailList().push(data.value());
		});

		let params = {
			title: this.email_title(),
			users: this.selectedAvailableUsers(),
			roles: this.selectedAvailableRoleNames(),
			address_strings: this.extraEmailList()
		};

		if(this.action() === 'create')
		{
			// TODO: add below api 
			let response = await Grape.fetches.postJSON('/api/reports/email_list/save',	params);		
			if (response)
				this.page.close(true);
		}
		else if(this.action() === 'edit')
		{
			params.list_id = this.page.bindings.initial_values.list_id;
			params.email_list_owner = this.page.bindings.initial_values.email_list_owner;

			// TODO: add below api 
			let response = Grape.fetches.postJSON('/api/reports/email_list/update', params);
			if (response)
				this.page.close(true);
		}
	}
}

class UserEmailListDialog
{
	constructor (bindings, elements)
	{
		this.bindings = bindings;
		this.elements = elements;
		this.viewModel = new UserEmailListDialogViewModel(this);
		this.name = 'UserEmailListDialog';
	}

	async init ()
	{
		Grape.cache.fetch('AccessRoles', (d) => {
			let arr = [];
			d.forEach((s) => { if (['all', 'guest', 'any'].indexOf(s.role_name) == -1) arr.push(s.role_name); });
			this.viewModel.availableRoleNames(arr);

			if (this.bindings.initial_values.roles)
				this.viewModel.selectedAvailableRoleNames(this.bindings.initial_values.roles);
		});

		let params = {
			table: 'v_users',
			schema: 'grape',
			sortfield: 'username'
		};

		let results = await Grape.fetches.getJSON('/api/record', params); 
		if (results)
		{
			if (results.result_count == 0 || results.records == undefined)
				results.records = [];

			let arr = [];
			results.records.forEach((s) => { arr.push(s.username); });
			this.viewModel.availableUsers(arr);

			if (this.bindings.initial_values.users)
				this.viewModel.selectedAvailableUsers(this.bindings.initial_values.users);
		}

		if (!this.bindings.initial_values.list_id )
		{
			this.viewModel.title('Add Email List');
			this.viewModel.action('create');
		}
		else
		{
			this.viewModel.title('Edit Email List');
			this.viewModel.action('edit');
			this.viewModel.email_title(this.bindings.initial_values.title);
			this.bindings.initial_values.address_strings.forEach((s) => { this.viewModel.extra_email_address.push({ value: ko.observable(s)}); });
		}
	}
}

export default {
	name: 'UserEmailList',
	dialog_class: UserEmailListDialog,
	template: template,
	provider: "ps"
};
