import report from './report';
import template from './report_definition.html';

class ReportDefinitionViewModel
{
	constructor (page)
	{
		this.page = page;
		this.params = {};
		this.report_data = ko.observableArray([]);

		this.description = ko.observable('');
		this.group = ko.observable('');
		this.report_definition_id = ko.observable('');
		this.version = ko.observable('');
		this.title = ko.observable('');
		this.query_text = ko.observable('');
		this.report_type = ko.observable('');
		this.function_schema = ko.observable('');
		this.function_name = ko.observable('');
		this.form_scope_id = ko.observable('');
		this.active = ko.observable('');
		this.report_id = ko.observableArray([]);
		this.default_function_criteria = ko.observable('');

		this.table_columns = ko.observable('');
		this.sort_field = ko.observable('');
		this.sort_order = ko.observable('');
		this.filter_join = ko.observable('');
		this.filters = ko.observable('');
		this.generated_event_data = ko.observableArray();

		this.checkbox_checked = ko.observable(false);
		this.isSelected = ko.observableArray([]);
		this.isSelected.subscribe(() => {
			if (this.isSelected().length > 0)
			{
				this.checkbox_checked(true);
			}
			else
				this.checkbox_checked(false);
		});
	}

	// Fetch report definition based on the report definition id
	async get_report_definition ()
	{
		let runtime_formatter = (value) => {
			return value ? moment.duration(value).humanize() : '-';
		};

		let initial_params = {
			schema: 'reports',
			table: 'report_definition',
			filter: [
				{field: 'report_definition_id', operand: '=', value: this.report_definition_id()},
				{field: 'version', operand: '=', value: this.version()}
			],
			limit: 1
		};

		let response = await Grape.fetches.getJSON('/api/record', initial_params);
		if (response.status === 'OK' && response.records.length > 0)
		{
			let record = response.records[0];
			this.title(record.name);
			this.description(record.description);
			this.group(record.group);
			this.query_text(record.definition.query_sql);
			this.report_type(record.report_type);
			this.function_schema(record.definition.function_schema);
			this.function_name(record.definition.function_name);
			this.form_scope_id(record.form_scope_id);
			this.version(record.version);
			this.active(record.active);
			this.default_function_criteria(record.default_function_criteria);
			this.generated_event_data(record.generated_event_data);

			if (this.report_type() === 'query')
			{
				this.sort_field(record.default_function_criteria.sortfield);
				this.sort_order(record.default_function_criteria.sortorder);
				this.table_columns(record.default_function_criteria.selected_columns.join(', '));

				if (record.default_function_criteria.use_filter === 'YES')
				{
					this.filter_join(record.default_function_criteria.filter_join);
					
					let filters = record.default_function_criteria.filter.filter(val => {
						if (val.value == '') { return false; } return true;
					}).map(fil => {
						return `${fil.field} ${fil.operator} ${fil.value}`
					});
					this.filters(filters.join(', '));
				}
			}
		
			let params = {
				schema: 'reports',
				table: 'v_reports',
				filter: [
					{field: 'report_definition_id', operand: '=', value: this.report_definition_id()},
					{field: 'version', operand: '=', value: this.version()}
				]
			};
			
			let results = await Grape.fetches.getJSON('/api/record', params);
			if (results.status === 'OK' && Array.isArray(results.records))
			{
				let processed_records = results.records.map(rec => {
					return {
						date_inserted: this.dateFormatter(rec.date_inserted),
						report_id: rec.report_id,
						report_function_criteria: rec.report_function_criteria ? JSON.stringify(rec.report_function_criteria) : '-',
						generated_by: rec.generated_by,
						runtime: runtime_formatter(rec.runtime)
					};
				});

				this.report_data(processed_records);
			}
			else
				this.report_data([]);
		}
		else
			console.error('Error fetching report definition or no records found');
	}
	
	generateReport ()
	{
		let bindings = {
			initial_values : {
				'report_definition_id': this.report_definition_id(),
				'version': this.version(),
				'report_type': this.report_type(),
				'function_schema': this.function_schema(),
				'function_name': this.function_name(),
				'active':true
			}
		};

		this.createReportCall = async (dialog_bindings) => {
			let func_var;
			if (dialog_bindings)
				func_var = {...bindings.initial_values, ...dialog_bindings.initial_values};
			else
				func_var = bindings.initial_values;

			let data = await Grape.fetches.postJSON('/api/report/create', func_var);
			if (data.status === 'OK')
			{
				if (!data.report)
				{
					Grape.alerts.alert({
						type: 'warning',
						message: 'User does not have the correct permission to run this report',
						title: 'Report Creation Error'
					});
				}
				else
				{
					let url = `/reports/report?report_id=${data.report.report_id}`;
					Grape.navigate(url);
				}
			}
		}

		this.createReport_function = async (dialog_bindings) => {
			if ( this.active() == true )
				this.createReportCall(dialog_bindings);
			else
			{
				let decision = await Grape.alerts.confirm({
					type: 'warning',
					message: 'Do you want to create a report for the old report definition version?',
					title: 'Confirm Report Generation',
					accept_text: 'Yes',
					cancel_text: 'No'
				});

				if (decision) 
				{
					if (decision === true)
						this.createReportCall(dialog_bindings);
					else
						return;
				}
			}
		};

		if (this.report_type() === 'function' && this.form_scope_id())
		{
			let function_bindings = { // title will be remove in future to use title provided in table
				title: 'Please provide parameters',
				scope_id: this.form_scope_id(),
				initial_values : {
					report_function_criteria : {}
				}
			}

			Grape.show_dialog('GrapeFormDialog', function_bindings,
			{
				onClose: (answer) => {
					if (answer)
					{
						function_bindings.initial_values.report_function_criteria = answer;
						this.createReport_function(function_bindings);
					}
					else
						return;
				}
			});
		}
		else
			this.createReport_function(null);
	}

	async edit_report_definition (data)
	{
		this.params = Object.assign({}, this.definition);
		
		let initial_values = {
			'report_definition_id': this.report_definition_id(),
			'name': data.title(),
			'description': data.description(),
			'group': data.group(),
			'report_type': data.report_type(),
			'query_sql': data.query_text() || '',
			'function_schema': data.function_schema() || '',
			'function_name': data.function_name() || '',
			'form_scope_id': data.form_scope_id() || '',
			'version': data.version(),
			'default_function_criteria': data.default_function_criteria() || '',
			'generated_event_data': data.generated_event_data() || '',
			'active': this.active()
		}

		// Edit report by calling the ReportDefinitionDialog
		let result = await Grape.dialog.open('ReportDefinition', {action: 'edit', initial_values: initial_values});
		if (result)
		{
			if (result.status === 'OK')
			{
				let url = ['/reports/definition', String(result.report_definition_id), String(result.version)].join('/');
				Grape.navigate(url);
			}
			else
			{
				Grape.alerts.alert({
					type: 'error',
					message: 'No data match the changes made to report_definition',
					title: 'No results found'
				});
			}
		}
	}

	async reportNotification (data)
	{
		let result = await Grape.dialog.open('ReportNotificationDialog', {report_name: data.title()});
	}

	async changeActiveReportDefinition (data)
	{
		let msg = 'Please confirm if you would like to change active state of this report definition';
		let decision = await Grape.alerts.confirm({
			type: 'warning',
			message: msg,
			title: 'Change Report Definition State',
			accept_text: 'Yes',
			cancel_text: 'No'
		});

		if (decision)
		{
			if (decision === true)
			{
				let definition_active_change;
				if (this.active())
					definition_active_change = false;
				else 
					definition_active_change = true

				let initial_values = {
					'report_definition_id': this.report_definition_id(),
					'version': data.version(),
					'active': definition_active_change
				}

				let results = await Grape.fetches.postJSON('/api/report/definition', initial_values);	
				if (results.status === 'OK')
				{
					this.get_report_definition();
				}
			}
			else 
				return;
		}
	}

	async deleteSelectedReports ()
	{
		let decision = await Grape.alerts.confirm({
			type: 'warning',
			title: 'Delete Reports',
			message: 'Delete the selected generated reports'
		});

		if (decision)
		{
			if (decision === true)
			{
				let reports = [];
				let reports_checked = this.isSelected();

				for (let i = 0; i < reports_checked.length; i++) {
					reports.push({
						report_id: reports_checked[i]
					});
				};
				
				let delete_reports = await Grape.fetches.postJSON('/api/reports/delete', {reports});
				if (delete_reports != -1)
				{
					this.checkbox_checked(false);
					this.get_report_definition();
				}
				else
					Grape.alerts.alert({type: 'warning', title: 'Unknown error', message: 'We have encountered an unknown error. Please contact IT support to look into this matter. [3]'});
			}	
		}
	}

	backToReports ()
	{
		Grape.navigate('/reports/');
	}

	copyLink (data)
	{
		let url = window.location.href;
		if (!data)
		{
			/*TODO: Get first definition number*/
			url = url.replace('definition', 'report');
			url += '/latest';
		}
		else
		{
			url = url.replace('definition', 'report');
			url += `/${data}`;
		}

		let link = `<a href='${url}'>${url}</a>`;
		navigator.clipboard.writeText(url);
		Grape.alerts.alert({type:'info', title: ' Link Copied', message:link});
	}

	downloadCSV (id, date)
	{
		let formatted_date = new Date(date);
		let export_filename = this.title()+' v'+id+'('+formatted_date.getFullYear() + '-' + (formatted_date.getMonth() + 1) + '-' + formatted_date.getDate() + ' ' + formatted_date.getHours() + ':' + formatted_date.getMinutes() + ':' + formatted_date.getSeconds()+').csv';
		window.open('/reports/'+id+'/export/csv/'+export_filename, '_blank');
	}

	dateFormatter (value, row, index)
	{
		return moment(value).format('YYYY-MM-DD HH:mm');
	}

	row_click (report)
	{
		let url = `/reports/report?report_id=${report.report_id}`;
		Grape.navigate(url);
	}

	btn_help_click ()
	{
		console.log('help clicked');
		//TODO add help menu again
		//Grape.show_help('/reports/help/report_menu_help.html');
	}
}

class ReportDefinitionPage
{
	constructor (bindings, element) 
	{
		this.bindings = bindings;
		this.element = element;
		this.viewModel = new ReportDefinitionViewModel(this);
		this.name = 'ReportDefinitionPage';
	}

	init ()
	{
		this.viewModel.report_definition_id(this.bindings.report_definition_id);
		this.viewModel.version(this.bindings.version);
	}

	updateData ()
	{
		this.viewModel.get_report_definition();
	}
}

export default {
	route: '[/]reports/definition/:report_definition_id/:version',
	page_class: ReportDefinitionPage,
	template: template
};
