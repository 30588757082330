
/* Application entry-point */

import appcaches from './caches/index.js';
import components from './components/index.js';
import dialogs from './dialogs/index.js';
import pages from './pages/index.js';

Grape.util = Grape.utils;

window.Grape.cache.registerCaches(appcaches);
window.Grape.component.registerComponents(components);
window.Grape.dialog.registerDialogs(dialogs);
window.Grape.pages.registerPages(pages);

Grape.registry.addItem(
    'PSServerDashboardPages',
    `${pages[0].title}`,
    {
        title: pages[0].title,
        icon: pages[0].icon,
        page_id: pages[0].page_id || pages[0].route || null,
        idx: pages[0].idx
    }
);