
import template from './auto_schedule_report.html';

class CreateReportAutoScheduleVM
{
	constructor (dialog)
	{
		this.dialog = dialog;
		this.active = ko.observable(true);
		this.email_list = ko.observable(false);
		this.report_definition_name = ko.observable('');
		this.process_id = ko.observable(null);
		this.mode = ko.observable('create');

		this.available_groups = ko.observableArray('');
		this.selected_group = ko.observable('');
		this.selected_group.subscribe(() => this.get_report_definition());

		this.selected_report_definition = ko.observable();
		this.available_report_definitions = ko.observableArray([]);
		this.selected_email_list = ko.observable();
		this.available_email_lists = ko.observableArray([]);

		this.auto_scheduler = ko.observable(null);
		this.auto_scheduler_id = ko.observable(null);

		// Autoscheduler options
		this.dow = ko.observable('');
		this.dom = ko.observable('');
		this.day_time = ko.observable('00:00');
		this.scheduled_interval_hr = ko.observable('00');
		this.scheduled_interval_min = ko.observable('00');
		this.scheduled_interval_sec = ko.observable('00');

		// Array with boolean observables for day of month
		this.day_of_months = [];
		for (let i = 1; i < 32; i++)
			this.day_of_months[i] = ko.observable(false);
		this.dom.subscribe((x) => this.update_day_of_months());

		// Array with boolean observables for day of week
		this.day_of_weeks = [];
		for (let i = 0; i < 7; i++)
			this.day_of_weeks[i] = ko.observable(false);
		this.dow.subscribe((x) => this.update_day_of_weeks());

		this.weekday_names = ko.observableArray(['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']);

		this.scheduled_interval = ko.pureComputed(() => {
			return [
				this.scheduled_interval_hr(), 
				this.scheduled_interval_min(), 
				this.scheduled_interval_sec()
			].join(':');
		}, this);

		this.time_type = ko.observable('tod'); // can be tod or toi
		this.auto_scheduler.subscribe((auto_scheduler) => { this.load(auto_scheduler); });
	}
	
	load (auto_scheduler)
	{
		// Reset values
		this.time_type('tod');
		this.day_time('00:00');
		this.scheduled_interval_hr('0');
		this.scheduled_interval_min('0');
		this.scheduled_interval_sec('0');
		this.dom('');
		this.dow('0000000');

		if (!auto_scheduler || !auto_scheduler.auto_scheduler_id)
			return;

		this.auto_scheduler_id(auto_scheduler.auto_scheduler_id);
		this.active(auto_scheduler.active);

		if (auto_scheduler.day_time && auto_scheduler.day_time != '')
		{
			this.scheduled_interval_hr('0');
			this.scheduled_interval_min('0');
			this.scheduled_interval_sec('0');

			this.time_type('tod');

			if (auto_scheduler.day_time.length > 5)
				auto_scheduler.day_time = auto_scheduler.day_time.slice(0, -3);

			this.day_time(auto_scheduler.day_time);
		}
		else if (auto_scheduler.scheduled_interval && auto_scheduler.scheduled_interval != '')
		{
			this.day_time('0');

			this.time_type('ti');

			let split = auto_scheduler.scheduled_interval.split(':');
			this.scheduled_interval_hr(split[0]);
			this.scheduled_interval_min(split[1]);
			this.scheduled_interval_sec(split[2]);
		}

		if (auto_scheduler.days_of_month && auto_scheduler.days_of_month != '')
			this.dom(auto_scheduler.days_of_month);

		if (auto_scheduler.days_of_week && auto_scheduler.days_of_week != '')
			this.dow(auto_scheduler.days_of_week);
	}

	//Update day_of_months array after dom() has been updated
	update_day_of_months ()
	{
		let days = [];
		if (this.dom().length > 0)
			if (this.dom() == '*')
				for (let i = 1; i <= 31; i++)
					days.push(i);
			else
				days = this.dom().split(',').map((x) => parseInt(x));

		for (let i = 1; i < 32; i++)
			if (days.indexOf(i) > -1)
				this.day_of_months[i](true);
			else
				this.day_of_months[i](false);
	}

	//toggle a day of the month on and off
	btn_DOM_click (day)
	{
		this.day_of_months[day](!this.day_of_months[day]());
	}

	//Update day_of_weeks array after dow() has been updated
	update_day_of_weeks ()
	{
		for (let i = 0; i < this.dow().length; i++)
		{
			if (this.dow()[i] === '1')
				this.day_of_weeks[i](true);
			else
				this.day_of_weeks[i](false);
		}
	}

	async get_report_definition ()
	{
		let group_value = this.selected_group();
		console.log(group_value);

		let result = await Grape.fetches.getJSON('/api/report/definition/all', {
			'group': group_value, 
			'active': true
		});

		if (result.status !== 'ERROR')
		{
			this.available_report_definitions(result.records);
		}
		else
			Grape.alert_api_error(result);

		console.log(result);
	}

	async btn_save_click ()
	{
		if (this.auto_scheduler() == null && this.selected_report_definition() == null)
		{
			Grape.alerts.alert({type: 'warning', title: 'AutoSchedule Process Error', message: 'Please select a report definition or process first!'});
			return;
		}

		let request = {
			auto_scheduler_id: this.auto_scheduler_id(),
			process_id: this.process_id(),
			dow: '',
			days_of_month: '',
			active: this.active()
		};

		if (this.selected_report_definition() != undefined)
		{
			request.report_definition_id = this.selected_report_definition().report_definition_id;
			request.report_definition_version = this.selected_report_definition().version;
		}
		else
		{
			request.report_definition_id = this.auto_scheduler().report_definition_id;
			request.report_definition_version = this.auto_scheduler().version;
		}

		// Get days of week
		for (let i = 0; i < this.day_of_weeks.length; i++)
			if (this.day_of_weeks[i]() === true)
				request.dow += '1';
			else
				request.dow += '0';

		let doms = [];
		// Get days of month
		for (let i = 1; i < 32; i++)
			if (this.day_of_months[i]() === true)
				doms.push(i);
		request.days_of_month = doms.join(',');

		// 'Time of day' or 'Interval'
		if (this.time_type() == 'tod')
			request.day_time = this.day_time();
		else 
			request.scheduled_interval = this.scheduled_interval();

		console.log(request);
		let result = await Grape.fetches.postJSON(`/api/report/autoschedule/save`, request);

		if (result.status == 'ERROR')
		{
			Grape.alerts.alert({type: 'danger', title: 'AutoSchedule Process Error', message: 'RESULT: '+result});
			return;
		}
		else
			Grape.alerts.alert({type: 'success', title: 'AutoSchedule Process', message: 'Auto scheduler saved'});

		this.dialog.close(true);
	}

	async btn_delete_click ()
	{
		if (!this.auto_scheduler_id())
			return;

		let accept = await Grape.alerts.confirm({type: 'warning', title: 'Confirm delete', message: 'Are you sure you want to delete this auto scheduler?'});
		if (!accept)
			return;
		
		let result = await Grape.fetches.fetch(`/api/bgworker/process/${this.process_id()}/autoscheduler/${this.auto_scheduler_id()}`, {method: 'DELETE'});

		if (result.status == 'ERROR')
		{
			Grape.alert_api_error(result);
			return;
		}
		else
			Grape.alerts.alert({type: 'success', title: 'Deleted', message: 'Autoscheduler has been deleted'});

		this.dialog.close(true);
	}

	btn_close_click ()
	{
		this.dialog.close(true);
	}
}

class CreateReportAutoSchedule
{
	constructor (bindings)
	{
		this.bindings = bindings;
		this.viewModel = new CreateReportAutoScheduleVM(this);

		this.viewModel.mode(this.bindings.mode);
		this.viewModel.auto_scheduler(bindings.process.auto_schedulers[0]);
		console.log(this.bindings);

		if (this.viewModel.auto_scheduler() != undefined)
		{
			this.viewModel.report_definition_name(this.viewModel.auto_scheduler().report_definition_name);
			this.viewModel.process_id(this.viewModel.auto_scheduler().process_id);
			this.viewModel.auto_scheduler_id(this.viewModel.auto_scheduler().auto_scheduler_id);
		}
	}

	async init () 
	{
		//Get report definition groups
		let result = await Grape.fetches.getJSON('/api/report/definition/groups', {'active': true});
		if (result.status === 'OK')
		{
			result.records.forEach((record) => {
				if(this.viewModel.available_groups.indexOf(record.group) == -1)
					this.viewModel.available_groups.push(record.group);
			});
		}

		// Get email list information from db
		let email_params = {
			schema: 'reports',
			table: 'email_list',
			sortfield: 'email_list_id',
			limit: 5000
		};

		let email_list = await Grape.fetches.getJSON("/api/record", email_params);
		
		if (email_list.status === 'OK')
			this.viewModel.available_email_lists(email_list.records);

		this.viewModel.get_report_definition();
	}
}

export default {
	name: 'CreateReportAutoSchedule',
	dialog_class: CreateReportAutoSchedule,
	template: template,
	provider: "ps"
}
