
import template from './report_definition_dialog.html';

class ReportDefinitionDialogViewModel
{
	constructor (page)
	{
		this.page = page;
		this.dialog_title = ko.observable('');
		this.action = ko.observable('');

		this.report_definition_id = ko.observable('');
		this.version = ko.observable('');
		this.query_text = ko.observable('');
		this.form_scope_id = ko.observable('');
		this.description = ko.observable('');
		this.group = ko.observable('');
		this.name = ko.observable('');
		this.form_scope_list = ko.observableArray([]);
		this.available_types = ko.observableArray(['Function', 'Query']);
		this.selected_type = ko.observableArray(['Function']);
		this.query_sql = ko.observable('');
		this.available_schemas = ko.observableArray([]);
		this.available_functions = ko.observableArray([]);
		this.selected_function = ko.observable('');
		this.raw_function_data = ko.observableArray([]);
		this.raw_table_data = ko.observableArray([]);
		this.selected_schema = ko.observable('');
		this.available_tables = ko.observableArray([]);
		this.selected_table = ko.observable('');
		this.available_columns = ko.observableArray([]);
		this.selected_columns = ko.observableArray([]);
		this.available_filter_options = ko.observableArray(['YES' , 'NO']);
		this.selected_filter_option = ko.observable('');
		this.available_filter_join_options = ko.observableArray(['AND', 'OR']);
		this.selected_filter_join_option = ko.observable('');
		this.sortfield = ko.observable('');
		this.sortorder = ko.observable('');
		this.active = ko.observable('');
		this.available_groups = ko.observableArray([]);
		
		this.filters = ko.observableArray([{ 
			field: ko.observable(''), 
			operator: ko.observable(''), 
			value: ko.observable('') 
		}]);

		this.selected_filter_join_option.subscribe(option => {
			if (option && (this.filters().length === 0 || this.filters() === ''))
				this.filters.push({ field: ko.observable(''), operator: ko.observable(''), value: ko.observable('') });
		});

		this.selected_type.subscribe(type => {
			if (type)
				this.selected_schema('');
		});

		this.selected_schema.subscribe(schema => {
			if (schema && this.selected_type() === 'Function')
			{
				let functions = this.raw_function_data().filter(item => item.routine_schema === schema).map(item => item.routine_name);
				this.available_functions(functions);
			}
			else if (schema && this.selected_type() === 'Query')
			{
				let tables = this.raw_table_data().filter(item => item.schema === schema).map(item => item.name);

				// Extract unique table names
				let unique_tables = [...new Set(tables.map(item => item))];
				this.available_tables(unique_tables);
			}
		});

		this.selected_table.subscribe(table => {
			if (table && this.selected_schema != '' && this.selected_type() === 'Query')
			{
				this.page.get_all_columns()
			}
		});

		this.title = ko.computed(() => {
			return this.dialog_title();
		}, this);
	}

	btn_add_filter_row_click ()
	{
		this.filters.push({ 
			field: ko.observable(''), 
			operator: ko.observable(''), 
			value: ko.observable('') 
		});
	}

	btn_remove_filter_row_click (index)
	{
		if (this.filters().length > 1)
			this.filters.splice(index, 1);
	}

	btn_close_click ()
	{
		this.page.close(false);
	}

	async btn_save_click ()
	{
		if (typeof this.selected_schema() === 'undefined')
		{
			Grape.alerts.alert({type: 'warning', title: 'Warning', message: 'No schema selected'});
			return;
		}
		else if (typeof this.selected_function() === 'undefined' && this.selected_type() === 'Function')
		{
			Grape.alerts.alert({type: 'warning', title: 'Warning', message: 'No function selected'});
			return;
		}
		else if (typeof this.selected_table() === 'undefined' && this.selected_type() === 'Query')
		{
			Grape.alerts.alert({type: 'warning', title: 'Warning', message: 'No table/view selected'});
			return;
		}
		else if (this.selected_columns().length === 0 && this.selected_type() === 'Query')
		{
			Grape.alerts.alert({type: 'warning', title: 'Warning', message: 'No columns selected'});
			return;
		}
		else if (typeof this.sortfield() === 'undefined' && this.selected_type() === 'Query')
		{
			Grape.alerts.alert({type: 'warning', title: 'Warning', message: 'No sortfield selected'});
			return;
		}

		let unwrap_filters = ko.toJS(this.filters()).map(filter => ({
			field: filter.field,
			operator: filter.operator,
			value: filter.value
		}));

		let params = {
			'name': this.name(),
			'description': this.description(),
			'group': this.group(),
			'report_type': this.selected_type(),
			'selected_schema': this.selected_schema(),
			'selected_table': this.selected_table(),
			'selected_columns': this.selected_columns(),
			'use_filter': this.selected_filter_option() != null ? this.selected_filter_option() : 'NO',
			'filter_join': this.selected_filter_option() === 'NO' ? '' : this.selected_filter_join_option(),
			'filter': this.selected_filter_option() === 'NO' ? '' : unwrap_filters,
			'sortfield': this.sortfield(),
			'sortorder': this.sortorder(),
			'function_name': this.selected_function(),
			'function_schema': this.selected_schema(),
			'criteria': this.query_sql(),
			'query_sql': this.query_sql(),
			'active': this.active()
		};

		if (this.action() === 'create')
		{
			params.report_definition_id = null;
			params.version = null;
		}
		else
		{
			params.report_definition_id = this.report_definition_id();
			params.version = null;
		}

		let data = await Grape.fetches.postJSON('/api/report/definition', params);
		if (data.status === 'OK')
			this.page.close(data);
	}
}

class ReportDefinitionDialog
{
	constructor (bindings, element)
	{
		this.bindings = bindings;
		this.element = element;
		this.viewModel = new ReportDefinitionDialogViewModel(this);
		this.name = 'ReportDefinitionDialog';

		this.title = ko.observable('');
	}

	async init ()
	{
		this.viewModel.action(this.bindings.action || 'create');
		if (this.bindings.initial_values)
		{
			this.viewModel.report_definition_id(this.bindings.initial_values.report_definition_id);
			this.viewModel.version(this.bindings.initial_values.version);
			this.viewModel.query_text(this.bindings.initial_values.query_sql);
			this.viewModel.form_scope_id(this.bindings.initial_values.form_scope_id);
			this.viewModel.description(this.bindings.initial_values.description);
			this.viewModel.group(this.bindings.initial_values.group);
			this.viewModel.name(this.bindings.initial_values.name);
			this.viewModel.selected_type(this.bindings.initial_values.report_type === 'query' ? 'Query' : 'Function');
			this.viewModel.active(this.bindings.initial_values.active);
		}

		this.get_scope_form_data(this.viewModel.action(), this.title());
		await this.get_all_tables();

		// Get all groups
		let available_groups = [];
		let result = await Grape.fetches.getJSON('/api/report/definition/groups');
		if (result.status !== 'ERROR')
		{
			result.records.forEach((record) => {
				if(available_groups.indexOf(record.group) == -1)
					available_groups.push(record.group);
			});

			this.viewModel.available_groups(available_groups);
		}
		else
			Grape.alert_api_error(result);

		// Get schemas and functions for the dialog
		let body = {
			schema: 'grape',
			table: 'v_pg_functions',
			filter: [
				{field: 'routine_schema', 'operator': '=', value: 'data_export'},
				{field: 'routine_schema', 'operator': '=', value: 'reports'},
				{field: 'routine_schema', 'operator': '=', value: 'demo'},
				{field: 'routine_schema', 'operator': '=', value: 'entity'},
				{field: 'routine_schema', 'operator': '=', value: 'fruits'}
			],
			filter_join: 'OR',
			limit: 5000
		}

		let data = await Grape.fetches.getJSON('/api/record', body);
		if (data.status === 'OK')
		{
			// Store raw data
			this.viewModel.raw_function_data(data.records);
	
			// Extract unique schemas
			let unique_schemas = [...new Set(data.records.map(item => item.routine_schema))];
			this.viewModel.available_schemas(unique_schemas);

			if (this.bindings.initial_values && this.viewModel.action() === 'edit')
			{
				let schema = this.bindings.initial_values.function_schema;
				let function_name = this.bindings.initial_values.function_name;

				// Set the schema and function if editing
				if (schema && this.bindings.initial_values.report_type === 'function')
				{
					this.viewModel.selected_schema(schema);
					this.viewModel.available_functions(this.viewModel.raw_function_data().filter(item => item.routine_schema === schema).map(item => item.routine_name));
				}
				else if(schema && this.bindings.initial_values.report_type === 'query')
				{
					this.viewModel.selected_schema(schema);
					this.viewModel.available_tables(this.viewModel.raw_table_data().filter(item => item.schema === schema).map(item => item.name));
				}
	
				if (function_name && this.bindings.initial_values.report_type === 'function')
					this.viewModel.selected_function(function_name);
				else if(schema && this.bindings.initial_values.report_type === 'query')
				{
					this.viewModel.selected_table(function_name);

					// First get all columns
					let response = await this.get_all_columns();
				}
			}

			if (this.bindings.initial_values && this.viewModel.action() === 'edit' && this.bindings.initial_values.report_type === 'query')
			{
				this.viewModel.selected_columns(this.bindings.initial_values.default_function_criteria.selected_columns || []);
				this.viewModel.sortfield(this.bindings.initial_values.default_function_criteria.sortfield || '');
				this.viewModel.sortorder(this.bindings.initial_values.default_function_criteria.sortorder || '');
				this.viewModel.selected_filter_option(this.bindings.initial_values.default_function_criteria.use_filter || 'NO');
				this.viewModel.selected_filter_join_option(this.bindings.initial_values.default_function_criteria.filter_join || '');
				this.viewModel.filters(this.bindings.initial_values.default_function_criteria.filter || []);
			}
		}
	}

	async get_scope_form_data (action, title)
	{
		if (!title)
			if (action === 'create')
				this.title('Add Report Definition');
			else
				this.title('Edit Report Definition');

		let scope_params = {
			schema: 'gf',
			table: 'scope',
			sortfield: 'scope_id',
			limit: 5000
		}

		let data = await Grape.fetches.postJSON('/api/record', scope_params);
		if (data.status === 'OK')
		{
			if (data.result_count > 0)
			{
				data.records.forEach((record) => {
					this.form_scope_list().push({'value': record.scope_id, 'text': record.name});
				});
			}
		}
	}

	async get_all_tables ()
	{
		// Get all the tables and views as options based on selected schema
		let response = await Grape.fetches.getJSON('/api/record', {
			schema: 'grape',
			table: 'v_pg_tables',
			limit: 5000,
			fields: ['name', 'schema'],
			filter: [
				{field: 'schema', 'operator': '=', value: 'entity'},
				{field: 'schema', 'operator': '=', value: 'reports'},
				{field: 'schema', 'operator': '=', value: 'demo'},
				{field: 'schema', 'operator': '=', value: 'demo_export'},
				{field: 'schema', 'operator': '=', value: 'fruits'}
			],
			filter_join: 'OR'
		});

		if (response.status === 'OK')
			this.viewModel.raw_table_data(response.records);
		else
			console.error(response);
	}

	async get_all_columns ()
	{
		// Get all the columns as options based on selected schema and table/view
		let response = await Grape.fetches.getJSON('/api/record', {
			schema: 'grape',
			table: 'v_pg_columns',
			limit: 5000,
			filter: [
				{field: 'schema_name', 'operator': '=', value: this.viewModel.selected_schema()},
				{field: 'table_name', 'operator': '=', value: this.viewModel.selected_table()}
			],
			fields: ['column_name']
		});

		if (response.status === 'OK')
			this.viewModel.available_columns(response.records.map(item => item.column_name));
		else
			console.error(response);
	}
}

export default {
	name: 'ReportDefinition',
	dialog_class: ReportDefinitionDialog,
	template: template,
	provider: "ps"
};
