
import user_email_list from './user_email_list_dialog.js';
import report_definition from './report_definition_dialog.js';
import send_email from './send_email_dialog.js';
import auto_schedule_report from "./auto_schedule_report.js";
import report_notification from "./report_notification.js";

export default [
	user_email_list,
	report_definition,
	send_email,
	auto_schedule_report,
	report_notification
];