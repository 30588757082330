
import template from './report_notification.html';

class ReportNotificationDialogVM
{
	constructor (page)
	{
		this.page = page;
		this.report_name = ko.observable();
		this.report_notification = ko.observableArray();
		this.notification_save = ko.observable();
		this.edit_mode = ko.observable(false);
		this.channels = ko.observableArray();

		this.active = ko.observable();							
		this.channel_name = ko.observable();
		this.event_data_match = ko.observable();
		this.subscription_uuid = ko.observable();
		this.template_data = ko.observable();
		this.template_name = ko.observable();
		this.template_namespace = ko.observable();
		this.to_address = ko.observable();
	}

	async load ()
	{
		await this.get_channels();
		
		let params = {
			schema: 'reports',
			table: 'v_report_notification',
			filter: [
				{field: 'event_data_match', operand: '=', value: this.report_name()},
			]
		};
		
		let results = await Grape.fetches.getJSON('/api/record', params);
		if (results.status === 'OK' && results.records.length > 0)
		{
			let records = results.records.map(x => {
				return {
					subscription_uuid: x.subscription_uuid,
					template_name: x.template_name,
					channel_name: x.channel_name,
					to_address: x.to_address,
					active: x.active
				}
			});
			this.report_notification(records);
			this.subscription_uuid(results.records[0].subscription_uuid);
		}
		console.log(this.report_notification());
	}

	async get_channels ()
	{
		const Channels = await Grape.cache.get('Channels');
		let channel = Channels.map(x => {
			return {
				name: x.name,
			}
		});
		this.channels(channel);
	}

	async save_notification (data)
	{
		let requests = await Grape.fetches.getJSON('/api/record', { 
			schema: 'events', 
			table: 'v_subscription',
			filter: [
				{field: 'subscription_uuid', operand: '=', value: this.subscription_uuid()},
			]
		});
		let request = requests.records[0];

		let obj = {}
		this.report_notification().forEach(function(notification) {
			let config_data = {
				to_address: notification.to_address, 
				channel_name: notification.channel_name.name,
				template_name: notification.template_name, 
				template_namespace: request.config_data.template_namespace,
				template_data: request.config_data.template_data
			}

			obj = {
				subscription_uuid: request.subscription_uuid,
				event_qname: `${request.event_namespace}.${request.event_object_type}.${request.event_name}`,
				action_qname: `${request.action_namespace}.${request.action_name}`,
				active: notification.active,
				idx: request.idx,
				event_data_match: request.event_data_match,
				config_data: config_data
			};
		});

		let result = await Grape.fetches.postJSON('/api/events/subscription/', obj);
		if (!result)
			throw new Error('Unable to save data');
		else
		{
			this.edit_mode(false);
			await this.load();
		}
			
	}

	async delete_subscription (data) {
		let result = await Grape.fetches.postJSON(`/api/subscription/delete`, {subscription_uuid: this.subscription_uuid()});
		if (result)
			this.load();
	}

	edit_notification() 
	{
		this.edit_mode(true);
	}

	btn_close_click ()
	{
		this.page.close(false);
	}
}

class ReportNotificationDialogClass
{
	constructor (bindings, elements)
	{
		this.bindings = bindings;
		this.elements = elements;
		this.viewModel = new ReportNotificationDialogVM(this);
		this.name = 'ReportNotificationDialog';
	}

	init ()
	{
		this.viewModel.report_name(this.bindings.report_name);
		this.viewModel.load();
	}
}

export default {
	name: 'ReportNotificationDialog',
	dialog_class: ReportNotificationDialogClass,
	template: template,
	provider: "ps"
};
