import template from './email_user_list.html';

class EmailUserListViewModel
{
	constructor (page)
	{
		this.page = page;
		this.email_role_list = ko.observableArray([]);
		this.email_id_list = ko.observableArray([]);
		this.list = ko.observableArray([]);
	}

	async get_user_email_list ()
	{
		let params = {
			schema: 'reports',
			table: 'email_list',
			sortfield: 'email_list_id',
			limit: 5000
		};

		let results = await Grape.fetches.getJSON("/api/record", params);

		if (results)
		{
			if (results.result_count == 0 || results.records == undefined)
				results.records = [];
				
			this.list(results.records);
		}
	}

	async edit_email_list (data)
	{
		let bindings = {
			initial_values : {
				'list_id': data.list_id,
				'title': data.title,
				'owner_id': data.owner_id,
				'users': data.users,
				'roles': data.roles,
				'address_strings': data.address_strings,
				'action': 'edit'
			},
			user_list : this.email_id_list(),
			roles : this.email_role_list()
		};

		let response = await Grape.dialog.open('UserEmailList', bindings);

		if (response)
			this.get_user_email_list();

		// TODO REMOVE USAGE OF user_email_list_dialog(bindings, callBack);
	}

	async btn_add_email_list_click ()
	{
		let bindings = {
			initial_values : {
				'action': 'create'
			},
			user_list : this.email_id_list(),
			roles : this.email_role_list()
		};

		let response = await Grape.dialog.open('UserEmailList', bindings);

		if (response)
			this.get_user_email_list();
		
		// TODO REMOVE USAGE OF user_email_list_dialog(bindings, callBack);
	}

	btn_help_click ()
	{
		// TODO: Grape.show_help does not exist anymore
		// Grape.show_help('/reports/help/email_user_list_help.html');
		console.log('show help clicked');
	}
}

class EmailUserListPage
{
	constructor (bindings, element)
	{
		this.bindings = bindings;
		this.element = element;
		this.viewModel = new EmailUserListViewModel(this);
		this.name = 'EmailUserListPage';
	}

	async init ()
	{
		// Limit set for future use if roles exceed 50
		let role_params = {
			schema: 'grape',
			table: 'v_access_roles'
		};

		let results = await Grape.fetches.getJSON("/api/record", role_params);

		if (results)
		{
			if (results.result_count == 0 || results.records == undefined)
				results.records = [];

			results.records.forEach((record) => {
				this.viewModel.email_role_list.push({"list_value_field": record.role_name, "list_label_field": record.role_name});
			});
		}

		let user_id_params = {
			schema: 'grape',
			table: 'v_users'
		};

		let response = await Grape.fetches.getJSON("/api/record", user_id_params);
		
		if (response)
		{
			if (response.result_count == 0 || response.records == undefined)
				response.records = [];

			response.records.forEach((record) => {
				this.viewModel.email_id_list.push({"list_value_field": record.user_id, "list_label_field": record.username});
			});
		}
	}

	updateData ()
	{
		this.viewModel.get_user_email_list();
	}
}

export default {
	route: '[/]reports/email_user_list',
	page_class: EmailUserListPage,
	template: template
};
