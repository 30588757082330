
// PAGES
import report_menu from './report_menu.js';
import report_definition from './report_definition.js';
import report from './report.js';
import email_user_list from './email_user_list.js';
import report_autoscheduler from './report_autoscheduler.js';

export default [
	report_menu,
	report_definition,
	report,
	email_user_list,
	report_autoscheduler
];


